<template>
  <b-modal
    :id="`order-claim-detail-modal-${orderClaimId}`"
    :modal-class="'default-modal'"
    header-class="justify-content-between"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >

    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>{{ title }}</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>

    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
      <template v-if="loader">
        <div class="text-center">
          <Loader
            :size="'big'"
          />
        </div>
      </template>
      <template v-else-if="orderClaim">
        <h3 class="font-size-medium">Dados gerais</h3>
        <p>
          <strong>Anunciante:</strong> {{ orderClaim.order.advertiser.name }}<br/>
          <strong>Valor:</strong> {{ orderClaim.order.amount / 100 | formatMoney }}<br/>
          <strong>Reivindicando:</strong> {{ orderClaim.claimed_user.name }}<br/>
          <strong>Status:</strong> <span style="font-size: 13px;" class="badge" :class="statusClass">{{ orderClaim.status | formatClaimStatus }}</span><br/>
          <strong>Tipo:</strong> {{ orderClaim.type == 'RENEWAL' ? 'Renovação' : 'Primeira compra' }}<br/>
          <strong>Cargo do vendedor:</strong> {{ orderClaim.responsible_profile | formatOrderClaimResponsibleProfile }}<br/>
          <template v-if="orderClaim.status !== 'PENDING'">
            <strong>Quem {{ orderClaim.status == 'REFUSED' ? 'recusou' : 'aprovou' }}:</strong> {{ orderClaim.verified_by.name }}<br/>
            <strong>Motivo:</strong> {{ orderClaim.reason }}<br/>
            <strong>Data da {{ orderClaim.status == 'REFUSED' ? 'recusa' : 'aprovação' }}:</strong> {{ orderClaim.verification_date | formatDate('DD/MM/YYYY HH:mm:ss') }}<br/>
          </template>
          <strong>Data de criação:</strong> {{ orderClaim.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}<br/>
          <strong>Ativo:</strong> {{ orderClaim.is_active ? 'Sim' : 'Não' }}
        </p>
        <template v-if="modalType == 'approval'">
          <div class="mt-4">
            <form class="default-form" @submit.prevent="">
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Motivo</label>
                <!--end::Label-->
                <!--begin::Input-->
                <textarea class="form-control" rows="5" v-model="form.reason"></textarea>
              </div>
              <div class="d-flex justify-content-around mt-3">
                <Button
                  class="btn btn-success"
                  @click="submit('APPROVED')"
                  :loading="loaderApproved"
                  :disabled="loaderApproved || loaderRefused"
                >
                  Aprovar
                </Button>
                <Button
                  class="btn btn-danger"
                  @click="submit('REFUSED')"
                  :loading="loaderRefused"
                  :disabled="loaderRefused || loaderApproved"
                >
                  Recusar
                </Button>
              </div>
            </form>
          </div>
        </template>
      </template>
    </div>
  </b-modal>
</template>

<script>

import OrderClaimsService from '@/modules/order-claims/services/order-claims-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'OrderClaimDetailModal',
  props: ['orderClaimId', 'modalType'],
  components: {
    AlertWraper,
    Button,
    Loader
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      orderClaim: null,
      loaderApproved: false,
      loaderRefused: false,
      form: {
        reason: '',
        status: ''
      }
    }
  },
  computed: {
    statusClass () {
      switch (this.orderClaim.status) {
        case 'APPROVED':
          return 'badge-light-success'
        case 'REFUSED':
          return 'badge-light-danger'
        default:
          return 'badge-light-warning'
      }
    },
    title () {
      if (this.modalType === 'approval') return 'Aprovação/recusa da reivindicação'
      else return 'Detalhes da reivindicação'
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.orderClaim = null
      this.content = null
    },
    /**
     * On modal shown
     */
    shown () {
      this.getOrderClaim()
    },
    /**
     * Get order
     */
    async getOrderClaim () {
      this.loader = true
      try {
        const orderClaim = await OrderClaimsService.getOrderClaim(this.orderClaimId)
        this.orderClaim = orderClaim
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Submit form
     */
    async submit (status) {
      this.setLoaderState(status, true)

      try {
        this.form.status = status
        await OrderClaimsService.changeClaimStatus(this.orderClaimId, this.form)
        this.$bvModal.hide(`order-claim-approval-modal-${this.orderClaimId}`)
        this.setLoaderState(status, false)
        const notification = {
          notification: {
            type: 'success',
            content: status === 'REFUSED' ? 'Recusado com sucesso' : 'Aprovado com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$emit('claimed', this.orderClaimId)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
        this.setLoaderState(status, false)
      }
    },
    /**
     * set loader states
     */
    setLoaderState (status, value) {
      if (status === 'APPROVED') {
        this.loaderApproved = value
      } else if (status === 'REFUSED') {
        this.loaderRefused = value
      }
    }
  }
}
</script>
